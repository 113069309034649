<template>
  <svg class="chart"
    :viewBox="`0 0 ${width} ${height * 0.9}`"
    :width="width"
    :height="height">
    <!-- Line -->
    <path class="line"
      :d="line" />
    <!-- Y Axis text -->
    <text v-for="tick in yAxis"
      class="y-axis-text"
      :originY="y(tick)"
      :y="y(tick)">{{ percentage(tick, 0) }}</text>
    <!-- Horizontal Grid -->
    <line v-for="tick in yAxis"
      class="y-axis"
      :key="y(tick)"
      x1="0%"
      x2="100%"
      :y1="y(tick)"
      :y2="y(tick)" />
  </svg>
</template>

<script setup lang="ts">
/**
 * TODO: fix issue, can't found `@types/d3-xxxx`, so disabled ts check
 */
// @ts-nocheck
import { line as shapeLine } from 'd3-shape';
import { extent } from 'd3-array';
import { scaleLinear } from 'd3-scale';
import type { IPerformance } from '~/api/chatbot';


type MessageCardProps = {
  performance: Array<IPerformance>,
  height?: number
  width?: number
}
const props = defineProps<MessageCardProps>()

const { percentage } = useFilter()

const {
  performance,
  height = 60,
  width = 188,
} = props

const data = performance
  .map(item => item.value)
  .filter((item): item is number => typeof item === 'number')
const yExtent = extent(data) as [number, number];

//invert range to support svg coordinate system
const y = scaleLinear()
  .domain(yExtent)
  .range([height * 0.95 , height * 0.05]);

const x = scaleLinear()
  .domain([0, data.length - 1])
  .range([0, width - 30]);

const line = shapeLine<number>()
  .x((_, index) => x(index))
  .y(d => y(d))(data);

const yAxis = y.ticks(4)
</script>

<style scoped lang="stylus">
.chart
  .line
    transform translateX(30px)
    stroke $primary
    stroke-width 1
    fill none
  .y-axis-text
    font-size 11px
    fill $black-0-42
    max-width 30px
  .y-axis
    stroke $black-0-12
    stroke-width 0.3
</style>