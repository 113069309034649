import { ISpeech, ServerSpeech, Speech } from "~/utils/speech";

export { Abbreviation } from "~/utils/speech";

export function useSpeech() {
  const speech = ref<ISpeech>(new ServerSpeech());
  const timer = ref<ReturnType<typeof setInterval>>();
  const { data, refresh } = useFetch<{ token: string; region: string }>(
    "/speech",
    { method: "POST" }
  );

  onMounted(() => {
    if (!data.value?.token || !data.value?.region) {
      refresh();
    } else {
      speech.value = new Speech(data.value.token, data.value.region);
    }

    timer.value = setInterval(() => {
      refresh();
    }, 120 * 1000);
  });

  watch(
    data,
    (_data) => {
      if (_data?.token && _data?.region) {
        speech.value = new Speech(_data.token, _data.region);
      }
    },
    {
      deep: true,
    }
  );

  onUnmounted(() => {
    speech.value.close();
    clearInterval(timer.value);
  });

  return speech;
}
