import { createAction } from "@/utils/api";

import type { IMessage } from "./types";

export * from "./types";

// import './mock'

/**
 * 發送訊息
 */
export const sendMessage = createAction<
  // Response
  {
    error: number;
    entries: Array<IMessage>;
  },
  {
    text: string;
  }
>("POST", "/api/:locale/chatbot/message");

/**
 * 意見回饋
 */
export const report = createAction<
  {
    error: number;
  },
  {
    text: string;
  }
>("POST", "/api/:locale/chatbot/report");

/**
 * 意見回饋
 */
export const rating = createAction<
  {
    error: number;
  },
  {
    score: number;
  }
>("POST", "/api/:locale/chatbot/rating");

export const speechToken = createAction<{ token: string; region: string }>(
  "POST",
  "/speech"
);
